import React from 'react';
// import { Link } from 'gatsby';

import Layout from "../components/Layout"
import SEO from "../components/seo"

const AboutPage = () => (
    <Layout>
        <SEO title="about" />
        <h1>About Page</h1>
    </Layout>
)

export default AboutPage;